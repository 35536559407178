<template>
  <form id="bb-contactform" action="/" method="POST">
    <input type="hidden" value="contact-request" name="type" />
    <div class="bb-contactform__container">
      <h2>Persönliche Daten</h2>
      <template v-for="input in personalInputs">
        <div :key="input.id" class="bb-input__container">
          <input
            :name="input.id"
            :type="input.type"
            :id="'bb-input-' + input.id"
            :placeholder="input.label"
            :required="input.required"
            :v-model="input.model"
          />
          <label :for="'bb-input-' + input.id">{{ input.label }}</label>
        </div>
      </template>
      <span>* Pflichtfelder</span>
    </div>
    <div class="bb-contactform__container">
      <h2>Daten zum gewünschten Termin{{ warning }}</h2>
      <template v-for="input in appointmentInputs">
        <div :key="input.id" class="bb-input__container">
          <input
            :name="input.id"
            :type="input.type"
            :id="'bb-input-' + input.id"
            :placeholder="input.label"
          />
          <label :for="'bb-input-' + input.id">{{ input.label }}</label>
        </div>
      </template>
      <div class="bb-input__container">
        <input
          type="text"
          id="input-services"
          name="services"
          placeholder="Wählen Sie eine Leistung +"
          @click="$emit('toggle-selectable-services')"
          v-model="selectedServices"
          readonly
        />
        <label for="input-services" class="bb-input__label--shown"
          >Gewünschte Leistung(en)</label
        >
      </div>
      <div class="bb-input__container bb-input__container--textarea">
        <textarea id="message" name="message"></textarea>
        <label for="message">Bemerkungen</label>
      </div>
      <div class="bb-input__container">
        <input type="checkbox" name="accept" id="accept" required />
        <label for="accept">
          Ich habe die
          <router-link :to="{ name: 'privacy-policy' }"
            >Datenschutzhinweise</router-link
          >
          gelesen, und stimme zu dass meine Angaben gespeichert und verarbeitet
          werden. *
        </label>
      </div>
      <div>
        <input
          @submit.prevent="checkAndSubmit"
          class="bb-button--ghost"
          type="submit"
          value="Senden"
        />
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    servicesSelected: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      warning: "",
      phone: "",
      email: "",
      personalInputs: [
        {
          id: "firstname",
          label: "Vorname *",
          type: "text",
          required: false,
          model: "",
        },
        {
          id: "name",
          label: "Name *",
          type: "text",
          required: true,
          model: "",
        },
        {
          id: "email",
          label: "E-Mail *",
          type: "email",
          required: true,
          model: "email",
        },
        {
          id: "phone",
          label: "Telefon",
          type: "tel",
          required: false,
          model: "phone",
        },
        {
          id: "city",
          label: "Ort",
          type: "text",
          required: false,
          model: "",
        },
        {
          id: "zipCode",
          label: "PLZ",
          type: "number",
          required: false,
          model: "",
        },
      ],
      appointmentInputs: [
        {
          id: "date",
          label: "Datum",
          type: "date",
          required: false,
        },
      ],
    };
  },
  methods: {
    checkAndSubmit() {
      if (!this.email && !this.phone) {
        this.warning = ". Bitte Telefon und/oder E-Mail angeben!";
      } else {
        this.$refs.form.submit();
      }
    },
  },
  computed: {
    selectedServices() {
      return this.servicesSelected.join(", ");
    },
  },
};
</script>

<style lang="scss">
#bb-contactform {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .bb-contactform__container {
    flex: 1;
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    min-width: 200px;

    h2 {
      text-align: center;
    }

    textarea {
      height: 90%;
    }

    &:first-of-type {
      .bb-input__container:first-of-type {
        position: absolute;
        top: -50px;
      }
    }
  }

  #input-services {
    cursor: pointer;

    &::placeholder {
      color: $tertiary-color;
    }
  }

  div button {
    color: $primary-color;
    background-color: white;
    border-color: white;
    margin: 10px 0px;
    font-weight: bold;
  }

  input[type="submit"],
  textarea {
    width: 100%;
  }

  .bb-button--ghost {
    color: white;
    border-color: $tertiary-color;
    background-color: $tertiary-color;
  }
}
</style>