import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import HomePage from './HomePage.vue'
import LegalNotesPage from './LegalNotesPage.vue'
import PrivacyPolicyPage from './PrivacyPolicyPage.vue'
import HeaderLogo from "./components/HeaderLogo.vue";
import LegalLinks from "./components/LegalLinks.vue";
import Meta from 'vue-meta';
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(VueAxios, axios)

Vue.component('header-logo', HeaderLogo);
Vue.component('legal-links', LegalLinks);

const routes = [
  { name: 'home', path: '/', component: HomePage },
  { name: 'legal-notes', path: '/Impressum', component: LegalNotesPage },
  { name: 'privacy-policy', path: '/Datenschutzerklärung', component: PrivacyPolicyPage }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: 'history',
  routes, // short for `routes: routes`
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
