<template>
  <div id="app">
    <main>
      <header>
        <div id="bb-header__intro">
          <header-logo></header-logo>
          <div class="bb-header__welcome">
            <h1>Willkommen!</h1>
            Wir bieten professionelle Reinigungen (insbesondere
            Fensterreinigung) für Privathaushalte und gewerbliche Zwecke an.<br /><br />
            <a class="" href="#" @click.prevent="scrollToAnchor('services')"
              >Unsere Leistungen &darr;
            </a>
          </div>
        </div>
        <div id="bb-header__aside">
          <img class="bb-image--background" src="./assets/main3.jpg" />
          <img class="bb-image--front" src="./assets/main.jpg" />
          <div class="bb-business-card">
            <strong>Antonio Ayoso</strong>
            <strong>Lidia Jiménez</strong>
            <span>Fensterreinigung</span>
            <span>Krefeld und Umgebung</span><br />
            <span
              >Tel.:
              <a class="bb-business-card--tel" href="tel:01728884714"
                >0172 888 47 14</a
              ></span
            >
            <a class="bb-business-card--email" href="mailto:info@andaclean.de"
              >info@andaclean.de</a
            ><br />
            <div>
              <a
                class="bb-button--ghost"
                href="#"
                @click.prevent="scrollToAnchor('contact')"
                >Kontaktanfrage</a
              >
            </div>
          </div>
        </div>
      </header>
      <section id="services">
        <h1>Unsere Leistungen im Überblick</h1>
        <services-selector
          :isSelectable="servicesSelectable"
          @update-services="updateSelectedServices"
        />
      </section>
      <request-call />
    </main>
    <footer id="contact">
      <h1>Kontaktanfrage</h1>
      <contact-form
        @toggle-selectable-services="toggleServicesSelectable"
        :services-selected="servicesSelected"
      />
    </footer>
    <!--HelloWorld msg="Welcome to Your Vue.js App" /-->
    <legal-links></legal-links>
  </div>
</template>
<script>
//import HelloWorld from "./components/HelloWorld.vue";
import ServicesSelector from "./components/ServicesSelector.vue";
import ContactForm from "./components/ContactForm.vue";
import RequestCall from "./components/RequestCall.vue";

export default {
  name: "App",
  components: {
    //HelloWorld,
    ServicesSelector,
    ContactForm,
    RequestCall,
  },
  data() {
    return {
      servicesSelectable: false,
      servicesSelected: [],
    };
  },
  methods: {
    toggleServicesSelectable() {
      this.servicesSelectable = true;
      this.scrollToAnchor("services");
    },
    scrollToAnchor(anchorName) {
      document
        .getElementById(anchorName)
        .scrollIntoView({ behavior: "smooth" });
    },
    updateSelectedServices(selectedServices) {
      this.servicesSelected = selectedServices;
    },
  },
};
</script>

<style lang="scss">
/*@font-face {
  font-family: "Nunito";
  src: local("Nunito"), url(./fonts/Nunito/Nunito-Regular.ttf);
}*/
@font-face {
  font-family: "Quattrocento";
  src: local("Quattrocento"),
    url(./fonts/Quattrocento/QuattrocentoSans-Regular.otf);
}

#app {
  font-family: Quattrocento, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;

  #bb-header {
    &__aside {
      flex: 1;
      position: relative;

      .bb-business-card {
        position: absolute;
        max-width: 370px;
        height: 220px;
        background-color: white;
        bottom: 20px;
        left: 20px;
        right: 20px;
        box-shadow: #6f6f6f 0px 0px 3px;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        padding: 20px;

        &--tel,
        &--email {
          font-size: 20px;
        }
      }

      .bb-image {
        &--background {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        &--front {
          width: 250px;
          position: absolute;
          left: -30px;
          bottom: 30%;
          box-shadow: black 3px 3px 20px;

          @media (max-width: 900px) {
            left: 50%;
            transform: translateX(-50%);
            top: -20px;
            flex-direction: column;
          }
        }
      }
    }
    &__intro {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 20px;

      .bb-header__welcome {
        margin: 100px 100px 0px 50px;
        flex: 1;
      }
    }
  }

  #contact {
    background-color: #125978;
    color: white;
    padding: 50px;

    h1 {
      text-align: center;
    }
  }

  #covid-banner {
    background-color: $secondary-color;
    color: white;
    padding: 50px;

    h1 {
      text-align: center;
    }
  }

  #legal-notes {
    padding: 25px 50px;
    text-align: center;
  }
}
</style>
