<template>
  <form class="bb-selection__container--checkbox">
    <template v-for="service in services">
      <div :key="service.id" class="bb-selection__element">
        <input
          v-if="isSelectable"
          type="checkbox"
          :id="service.id"
          :value="service.name"
          v-model="selectedServices"
          @change="$emit('update-services', selectedServices)"
        />
        <label :for="service.id" class="">
          <div class="bb-checkbox"></div>
          <img :src="require(`../assets/service_${service.id}.jpg`)" />
          <span class="bb-label__text">{{ service.name }}</span>
        </label>
      </div>
    </template>
  </form>
</template>

<script>
export default {
  props: {
    isSelectable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      services: [
        {
          id: 1,
          name: "Fenster- und Jalousienreinigung",
        },
        {
          id: 2,
          name: "Bauschlussreinigung",
        },
        {
          id: 3,
          name: "Endreinigung von Mietwohnungen",
        },
        {
          id: 4,
          name: "Professionelle\nBad- und Küchenreinigung",
        },
      ],
      selectedServices: [],
    };
  },
};
</script>

<style lang="scss">
.bb-selection__container--checkbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .bb-selection__element {
    flex: 1;
    position: relative;
    height: 350px;
    min-width: 200px;
    margin: 20px;

    input[type="checkbox"] {
      opacity: 0;
      .bb-checkbox {
        border: 1px solid #125978;
        transform: translateX(-50%);
        border-radius: 50%;
        width: 10px;
        height: 10px;
      }

      & ~ label {
        cursor: pointer;
        border: 1px solid $primary-color;

        &:hover {
          color: $secondary-color;
        }
      }

      &:checked {
        & + label {
          background-color: $primary-color;
          color: $secondary-color;
        }
        & .bb-checkbox {
          background-color: $secondary-color;
        }
      }
    }

    label {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      padding: 10px;
      font-weight: bold;
      text-transform: uppercase;

      img {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        height: 200px;
        margin: 10px 0px;
        border-radius: 50%;
      }

      .bb-label__text {
        position: absolute;
        top: 270px;
      }
    }
  }
}
</style>