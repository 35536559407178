<template>
  <transition name="fade">
    <router-view></router-view>
  </transition>
</template>
<script>
export default {
  metaInfo: {
    // Children can override the title.
    title: "Gebäudereinigung und Glasreinigung",
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: "%s | ANDA CLEAN",
    // Define meta tags here.
    meta: [
      { "http-equiv": "Content-Type", content: "text/html; charset=utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Wir bieten professionelle Reinigungen (insbesondere Fensterreinigung) für Privathaushalte und gewerbliche Zwecke in Krefeld und Umgebung an.",
      },
    ],
  }
};
</script>
<style lang="scss">
@import "@/scss/_basic.scss";
@import "@/scss/app.scss";
</style>