<template>
  <div class="bb-button--call">
    <span @click="showingModal = !showingModal">
      <!--svg height="20px" width="20px">
        <g
          transform="translate(-14.397897,2.5023628)"
          id="layer1"
          inkscape:groupmode="layer"
          inkscape:label="Capa 1"
        >
          <path
            sodipodi:nodetypes="cssssccsccsc"
            id="path12"
            d="m 49.432867,4.1980574 c 0,0 -30.07921,10.7537146 -29.941471,24.2626126 0.15158,14.866371 -4.763418,68.662206 41.509763,118.18502 46.273221,49.5228 51.036661,49.5228 65.326941,50.63567 14.29025,1.11287 48.99512,0.55644 53.07806,-5.56436 4.08293,-6.1208 -27.9,-53.41785 -27.9,-53.41785 -8.30565,-6.29317 -24.95123,11.12871 -37.42686,16.69307 0,0 -38.787894,-22.81387 -51.036674,-47.29706 C 50.793842,83.211977 51.47433,59.841662 51.47433,59.841662 61.556092,53.024399 82.288443,48.245428 81.719617,39.389874 c 0,0 -15.955041,-37.973997 -24.120894,-36.8611254 -8.165856,1.1128715 -8.165856,1.6693088 -8.165856,1.6693088 z"
            style="
              fill: #000000;
              stroke: #000000;
              stroke-width: 10;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
              fill-opacity: 1;
            "
          />
          <g
            transform="translate(4.7625002)"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
            id="g850"
          >
            <g
              style="
                stroke-width: 10;
                stroke-miterlimit: 4;
                stroke-dasharray: none;
              "
              id="g845"
              transform="translate(14.81667,-2.1166667)"
            >
              <path
                sodipodi:nodetypes="ccc"
                id="path839"
                d="M 164.79762,35.605365 108.85714,85.498222 V 45.432746"
                style="
                  fill: none;
                  stroke: #000000;
                  stroke-width: 10;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                  stroke-opacity: 1;
                "
              />
              <path
                sodipodi:nodetypes="cc"
                id="path841"
                d="m 108.85714,86.027389 h 40.82143"
                style="
                  fill: none;
                  stroke: #000000;
                  stroke-width: 10;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                  stroke-opacity: 1;
                "
              />
            </g>
          </g>
        </g>
      </svg-->
      Ich wünsche einen Rückruf</span
    >
    <form v-if="showingModal" class="bb-modal-phone" action="/" method="POST">
      <input type="hidden" value="call-request" name="type" />
      <div class="bb-input__container">
        <input
          type="text"
          id="bb-modal-firstname-input"
          placeholder="Vorname *"
          name="firstname"
        />
        <label for="bb-modal-firstname-input">Vorname</label>
      </div>
      <div class="bb-input__container">
        <input
          type="tel"
          id="bb-modal-phone-input"
          placeholder="Telefon *"
          name="phone"
          required
        />
        <label for="bb-modal-phone-input">Telefon *</label>
      </div>
      <div class="bb-input__container">
        <input
          type="text"
          id="bb-modal-name-input"
          placeholder="Name *"
          name="name"
          required
        />
        <label for="bb-modal-name-input">Name *</label>
      </div>
      <div class="bb-input__container">
        <input type="checkbox" name="accept" id="accept" required />
        <label for="accept">
          Ich habe die
          <router-link :to="{ name: 'privacy-policy' }">
            Datenschutzhinweise
          </router-link>
          gelesen, und stimme zu dass meine Angaben gespeichert und verarbeitet
          werden.&nbsp;*
        </label>
      </div>
      <span>* Pflichtfelder</span>
      <input class="bb-button--ghost" type="submit" value="Senden" />
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showingModal: false,
    };
  },
};
</script>
<style lang="scss">
.bb-button--call {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: $tertiary-color;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-weight: bold;
  padding: 20px 50px;
  font-size: 20px;
  cursor: pointer;

  > span {
    text-align: center;
  }

  @media (max-width: 900px) {
    bottom: 20px;
    top: unset;
    right: unset;
    margin: 30px;
    position: sticky;
  }

  .bb-modal-phone {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .bb-input__container:first-of-type {
      position: absolute;
      top: -100px;
    }

    .bb-button--ghost {
      border-color: white;
      color: $tertiary-color;
      background-color: white;
    }
  }
}
</style>